import { Injectable } from '@angular/core';
import { HashMap, TranslocoService } from '@jsverse/transloco';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from './local-storage.services';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(
    private readonly transloco: TranslocoService,
    private readonly localStorageService: LocalStorageService,
  ) {}

  setActiveLanguage(lang?: string): void {
    const availableLangs = this.transloco.getAvailableLangs() as string[];
    const languageFromLocalStorage = this.localStorageService.getItem('lang');

    const targetLang =
      lang && availableLangs.includes(lang)
        ? lang
        : (languageFromLocalStorage ?? this.getBrowserOrFallbackLanguage(availableLangs));

    this.transloco.setActiveLang(targetLang);
    this.localStorageService.setItem('lang', targetLang);
  }

  getActiveLanguage(): string {
    return this.transloco.getActiveLang();
  }

  private getBrowserOrFallbackLanguage(availableLangs: string[]): string {
    return (
      availableLangs.find((lang) => navigator.languages.includes(lang)) ??
      environment.general.internationalFallbackLanguage
    );
  }

  translateOnRuntime(translationKey: string, params?: HashMap): string {
    const translation = this.transloco.translate(translationKey, params);
    if (translationKey === translation) {
      const parts = translationKey.split('.');
      return parts[parts.length - 1];
    }
    return translation;
  }
}
